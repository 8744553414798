export function getCountryFromCurrency(currencyCode) {
	const currencyMappings = {
		USD: "US",
		GBP: "GB",
		EUR: "DE",
		RON: "RO",
		// Add more currency to country mappings as needed
	};

	// Convert the currency code to uppercase for consistency
	const uppercaseCurrencyCode = currencyCode.toUpperCase();

	// Check if the currency code exists in the mappings
	if (currencyMappings.hasOwnProperty(uppercaseCurrencyCode)) {
		return currencyMappings[uppercaseCurrencyCode];
	}

	// Return null or any default value if the currency code is not found
	return null;
}
