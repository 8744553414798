import React from "react";
import { useTranslation } from "react-i18next";

const FailedMessage = ({ handleBack }) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md mx-2 sm:mx-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-12 w-12 text-red-500 mx-auto mb-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1.414-9.414l1.414-1.414 4 4-1.414 1.414-4-4zM4 10a6 6 0 1112 0 6 6 0 01-12 0z"
            clipRule="evenodd"
          />
        </svg>
        <h1 className="text-2xl font-semibold text-gray-800 text-center mb-4">
          {t("FAILED_PAGE.DONATION_FAILED")}
        </h1>
        <p className="text-gray-600 text-center">
          {t("FAILED_PAGE.OOPS_MESSAGE")}
        </p>
        <button
          className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-full mt-6 mx-auto block"
          onClick={handleBack}
        >
          {t("FAILED_PAGE.TRY_AGAIN")}
        </button>
      </div>
    </div>
  );
};

export default FailedMessage;
