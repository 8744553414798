import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import SelectLang from "./SelectLanguage";
import { BankSvg, CardSvg, PayPalSvg } from "./svgs";
import { toast } from "react-toastify";
import { getInputByName } from "../helpers/getInputElemByName";
const MainForm = ({
  formRef,
  handlePayment,
  handleChange,
  userPayment,
  method,
  setMethod,
  tab,
  setTab,
  loading,
  lang,
  setLang,
}) => {
  const { t } = useTranslation();
  const payment_method = [
    { name: "card", icon: CardSvg },
    { name: "paypal", icon: PayPalSvg },
    { name: "transfer", icon: BankSvg },
  ];
  const toastIdRef = useRef(null);
  const buttonRef = useRef();
  // const [errors, setErrors] = useState({ cur: "", amount: "" });
  const donation_type = ["monthly", "once"];

  // useEffect(() => {
  // 	buttonRef.current.disabled = false;
  // }, [userPayment.cur, userPayment.amount]);
  return (
    <div className="flex  md:justify-end justify-center min-h-screen items-center">
      {/* <div className="fixed left-0 right-0 z-[-1] w-full h-full opacity-[0.3]"></div> */}
      <div className="mx-4 relative lg:pe-12  md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl">
        <form
          ref={formRef}
          onSubmit={(e) => {
            e.preventDefault();
            let toastId = 10 * Math.random();
            const validateEmtyField = (name, errKey) => {
              const elem = getInputByName(e, name);
              elem.focus();
              toast.warn(t(`ERRORS.${errKey}`), {
                toastId,
              });
              if (toastIdRef.current) {
                toast.dismiss(toastIdRef.current);
              }
              toastIdRef.current = toastId;
            };
            if (method !== "transfer" && userPayment.amount === "")
              return validateEmtyField("amount", "FIELD_REQUIRED");

            if (method !== "transfer" && userPayment.cur === "")
              return validateEmtyField("cur", "SELECT_CURRENCY");
            handlePayment(e);
          }}
          className="flex flex-col main_form gap-4 md:gap-8 border-2 border-[#e2e8f0] bg-[#F1F3F5] z-[1] p-4 md:p-9 rounded-xl w-full min-w-[335px]  md:w-[30rem]"
        >
          <div>
            <label className="uppercase flex justify-between items-center font-semibold text-[12px] md:text-[18px] text-[#8C9096]  ">
              <span>{t("DONATION_AMOUNT")}</span>
              <SelectLang lang={lang} setLang={setLang} />
            </label>
            <input
              name="amount"
              min={1}
              type="number"
              // required={method !== "bank" ? true : false}
              value={userPayment.amount}
              onChange={handleChange}
              className="w-full bg-[#FBFCFD] focus:outline-[#0057B7] border-2 border-slate-200 mt-1 md:mt-3 px-3 py-3 rounded-[10px] text-[12px] md:text-[18px]"
            />
          </div>
          <div>
            <label className="uppercase text-[12px] md:text-[18px] font-semibold text-[#8C9096] mb-4 md:mb-10">
              {t("CURRENCY_TYPE")}
            </label>
            <select
              name="cur"
              // required={method !== "bank" ? true : false}
              onChange={handleChange}
              className="w-full bg-[#FBFCFD] text-[#99AFCB] border-2 border-slate-200 focus:outline-[#0057B7] mt-1 md:mt-3 px-3 py-3 rounded-[10px] md:text-[18px] text-[12px]"
            >
              <option value="" defaultValue={""}>
                {t("SELECT_CURRENCY")}
              </option>
              <option value="usd">$ USD</option>
              <option value="eur">€ EUR</option>
              <option value="gbp">£ GBP</option>
              <option value="ron">RON</option>
            </select>
          </div>
          <div>
            <label className=" uppercase text-[12px] md:text-[18px] text-[#8C9096] font-semibold mb-2">
              {t("DONATION_TYPE")}
            </label>
            <div className="flex mt-1 md:mt-3 rounded-[10px] items-center">
              {donation_type.map((item, ind) => (
                <button
                  type="button"
                  key={ind}
                  onClick={() => setTab(item)}
                  className={` ${
                    tab === item
                      ? " border-2  border-[#0057B7]  text-black outline-none"
                      : "  border-2 "
                  } w-full  hover:bg-white capitalize text-[12px] md:text-[16px] font-bold text-[#99AFCB]  rounded-[10px] py-3`}
                >
                  {t(item.toUpperCase())}
                </button>
              ))}
            </div>
          </div>
          <div>
            <label className="uppercase text-[12px] md:text-[18px]  font-semibold text-[#8C9096] mb-6 md:mb-12">
              {t("PAYMENT_METHOD")}
            </label>

            <div className=" flex justify-between mt-1 md:mt-3 rounded-[10px] border-slate-200 border-2  items-center  ">
              {payment_method.map((i, ind) => (
                <button
                  type="button"
                  key={ind}
                  onClick={() => setMethod(i.name)}
                  className={`${
                    method === i.name
                      ? " border-2  text-black  border-[#0057B7] "
                      : "text-[#929296]"
                  } min-w-[33%] md:h-[76px] h-[54px] flex justify-center hover:bg-white text-[12px] md:text-[16px]   font-bold capitalize items-center rounded-[10px] py-1 md:py-3`}
                >
                  <div className=" flex justify-center items-center  flex-col">
                    {i.icon}
                    {i.name}
                  </div>
                </button>
              ))}
            </div>
          </div>
          <div>
            <button
              type="submit"
              ref={buttonRef}
              className="flex gap-2 text-[13px] md:text-[18px] items-center justify-center w-full text-center bg-[#0057B7] py-3 rounded text-white font-semibold text-base tracking-[0.085rem] capitalize"
            >
              {t("DONATE")}
              <ClipLoader
                color={"white"}
                loading={loading}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
                // cssOverride={override}
              />
            </button>
          </div>
          <div className="flex text-[12px] md:text-[16px] underline justify-center items-center">
            <Link to={`/subscription?lang=${lang}`}>
              {" "}
              {t("CANCEL_SUBSCRIPTION")}?
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MainForm;
