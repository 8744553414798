import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const SelectLang = ({ lang, setLang }) => {
	const languages = [
		{ view: "RO", code: "ro", name: "Romanian" },
		{ view: "EN", code: "en", name: "English" },
		{ view: "RU", code: "ru", name: "Russian" },
		{ view: "UA", code: "ukr", name: "Ukrainian" },
	];

	const { i18n } = useTranslation();
	const navigate = useNavigate();
	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang, i18n]);

	return (
		<div className=" w-fit select_lang p-1 rounded  ">
			{languages.map((item, ind) => (
				<button
					title={item.name}
					key={ind}
          style={{color:lang?.toLowerCase() === item.code?.toLowerCase()?'white':'initial',background:lang?.toLowerCase() === item.code?.toLowerCase() ?'#0251B3':'initial'  }}
					type="button"
					onClick={(e) => {
						setLang(item.code);
						navigate(`/?lang=${item.code}`);
					}}
					className={`p-1 rounded text-center text-[12px] mx-[1px] bg-slate-300 border-none outline-none`}
		
					value={item.code}>
					{item.view}
				</button>
			))}
		</div>
	);
};

export default SelectLang;
