export default function getCurrencySymbol(currencyName) {
	const currencySymbols = {
		usd: "$",
		eur: "€",
		gbp: "£",
		ron: "RON",
		// Add more currency symbols as needed
	};

	return currencySymbols[currencyName.toLowerCase()] || '$';
}
