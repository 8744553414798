import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import PaymentForm from "./components/PaymentForm";
import Result from "./components/Results";

import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CancelSubscription from "./components/suscription";
function App() {
	const [lang, setLang] = useState("RO");
	const location = useLocation().search;
	const { i18n } = useTranslation();
	useEffect(() => {
		const lng = new URLSearchParams(location).get("lang");
		i18n.changeLanguage(lng);
		setLang(lng || "ro");
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<ToastContainer />
			<Routes>
				<Route
					path="/"
					element={<PaymentForm lang={lang} setLang={setLang} />}
				/>
				<Route path="/result" element={<Result lang={lang} />} />
				<Route
					path="/subscription"
					element={<CancelSubscription lang={lang} />}
				/>
				{/* <Route path="/sub/:id" element={<Suscription />} /> */}
			</Routes>
		</>
	);
}

export default App;
