export const getInputByName = (formEvent, inputName) => {
	// Access the form elements using formEvent.target
	const formElements = formEvent.target.elements;

	// Iterate over form elements to find the input with the specified name
	for (let i = 0; i < formElements.length; i++) {
		const element = formElements[i];
		if (element.name === inputName) {
			return element;
		}
	} // If no matching input element is found
	return null;
};
