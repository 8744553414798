import { getInputByName } from "./getInputElemByName";
const emailPattern = "\\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Z|a-z]{2,}\\b";
export const emailRegex = new RegExp(emailPattern);
let toaseRef = null;
export const handleValidation = ({ e, toast, t, name, email }) => {
	let toastId = 10 * Math.random();
	const validateEmtyField = (name) => {
		const elem = getInputByName(e, name);
		elem.focus();
		toast.warn(t("ERRORS.FIELD_REQUIRED"), { toastId });
		toaseRef && toast.dismiss(toaseRef);
		toaseRef = toastId;
	};
	if (name === "") return validateEmtyField("name");
	if (email === "") return validateEmtyField("email");
	const isValidEmail = emailRegex.test(email);
	if (!isValidEmail) {
		const elem = getInputByName(e, "email");
		elem.focus();
        toast.warn(t("ERRORS.INVALID_EMAIL"),{toastId});
        toaseRef && toast.dismiss(toaseRef);
        toaseRef = toastId;
		return
	}
	return "success";
};
