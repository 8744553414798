import React, { useState } from "react";
// import { useLocation } from "react-router-dom";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { FaXmark } from "react-icons/fa6";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import getCurrencySymbol from "../helpers/getCurrencySymble";
import { useTranslation } from "react-i18next";
import { handleValidation } from "../helpers/handlePaymentValidation";
const BASE_URL = process.env.REACT_APP_API_URL;
const CheckoutForm = ({
  clientSecret,
  cur,
  intentId,
  amount,
  tab,
  setPayPal,
  formReset,
  lang,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (handleValidation({ e: event, t, name, email, toast }) !== "success")
        return;
      if (!stripe || !elements) {
        return;
      }
      setLoading(true);
      let options = {
        return_url: `${process.env.REACT_APP_CLIENT_URL}/result?lang=${lang}`,
        mandate_data: {
          customer_acceptance: {
            type: "online",
            online: {
              infer_from_client: true,
            },
          },
        },
      };
      if (tab === "once") {
        return handleOneTimePayment({ options });
      }
      await handleMonthlyPayment({ options });
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error.message);
    }
  };

  // one time paymment handler
  const handleOneTimePayment = async ({ options }) => {
    try {
      const { error } = await stripe.confirmPayPalPayment(
        clientSecret,
        options
      );

      if (error) {
        console.log(error);
        setLoading(false);
        toast.error(error?.message);
        // Inform the customer that there was an error.
      }
      return;
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  // monthly payment handler
  const handleMonthlyPayment = async ({ options }) => {
    try {
      const res = await fetch(`${BASE_URL}/update-setup-intent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Adjust the content type as needed
        },
        body: JSON.stringify({
          cur,
          amount,
          name,
          email,
          intentId,
          locale: lang,
        }),
      });
      const data = await res.json();

      if (data.error) {
        setLoading(false);
        toast.error(t(data.error?.message));
        return;
      }
      // Redirects away from the client

      const { error } = await stripe.confirmPayPalSetup(clientSecret, options);

      if (error) {
        console.log(error);
        setLoading(false);
        toast.error(error?.message);
        // Inform the customer that there was an error.
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <div className="flex flex-col sm:flex-row w-full sm:w-[50rem] overflow-hidden rounded-xl">
      <div className="border-2  basis-[40%] bg-slate-40 bg-blue-100 border-r-2 border-blue-100 w-full"></div>
      <div className="px-4 sm:px-8 py-6 basis-[60%]">
        <div className="flex justify-end">
          <FaXmark
            className="text-2xl cursor-pointer text-blue-500"
            onClick={() => {
              setPayPal(false);
              formReset();
            }}
          />
        </div>
        <h4 className="text-black font-semibold text-lg mb-4">
          {t("MODAL.PAYMENT_DETAILS")}
        </h4>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4 mt-4 sm:mt-8">
            <div>
              <label className="font-semibold text-lg text-[#8C9096] mb-2 sm:mb-4">
                {t("MODAL.FULL_NAME")}
              </label>
              <input
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full text-black focus:outline-blue-600 bg-[#FBFCFD] border-2 border-slate-200 mt-2 sm:mt-3 px-3 py-3 rounded-[10px]"
              />
            </div>
            <div>
              <label className="font-semibold text-lg text-[#8C9096] mb-2 sm:mb-4">
                {t("MODAL.EMAIL")}
              </label>
              <input
                name="email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full text-black bg-[#FBFCFD] focus:outline-[#0057B7] border-2 border-slate-200 mt-2 sm:mt-3 px-3 py-3 rounded-[10px]"
              />
            </div>
            <div className="border-2 border-slate-200 focus:outline-blue-600 rounded-[10px] px-3 py-3 sm:py-4">
              <PaymentElement />
            </div>
            <div>
              <button
                className="bg-blue-600 flex items-center justify-center gap-2 w-full py-3 rounded-xl"
                disabled={!stripe}
              >
                <span className="">
                  {t("DONATE")} {amount} {getCurrencySymbol(cur)}
                </span>
                <ClipLoader
                  loading={loading}
                  color={"white"}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </button>
            </div>
            {/* Show error message to your customers */}
            {/* {errorMessage && <div>{errorMessage}</div>} */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CheckoutForm;
