import React  from "react";
import { useTranslation } from "react-i18next";
import { FaXmark } from "react-icons/fa6";

const Bank = ({ setBank }) => {

  const {t} = useTranslation()
  return (
    <div className="flex flex-col sm:flex-row w-full sm:w-[50rem] overflow-hidden rounded-xl">
      <div className="border-2  basis-[40%] bg-slate-40 bg-blue-100 border-r-2 border-blue-100 w-full"></div>
      <div className="px-4 sm:px-8 py-6 basis-[60%]">
        <div className=" flex justify-between items-center">
          <h4 className=" text-black font-semibold  text-lg mb-4">
         {   t('TRANSFER.Bank_Details')}
          </h4>{" "}
          <div className=" flex justify-end">
            <FaXmark
              className="  text-2xl cursor-pointer text-blue-500"
              onClick={() => setBank(false)}
            />
          </div>
        </div>

        <div className=" flex flex-col gap-8 mt-8 ">
          <div>
            <label className=" font-semibold text-[18px]    text-[#8C9096]   mb-10">
              {t('TRANSFER.Beneficiar')}:
            </label>
            <div className=" text-black font-semibold mt-2">
              {t('TRANSFER.FUNDATIA_INTUKROM')}
            </div>
          </div>
          <div>
            <label className=" font-semibold text-[18px]    text-[#8C9096]   mb-10">
              {t('TRANSFER.RON')}:
            </label>
            <div className=" text-black font-semibold mt-2">
            {t('TRANSFER.RON_Account')}
            </div>
          </div>
          <div>
            <label className=" font-semibold text-[18px]    text-[#8C9096]   mb-10">
            {t('TRANSFER.EUR')}:
            </label>
            <div className=" text-black font-semibold mt-2">
            {t('TRANSFER.EUR_Account')}
            </div>
          </div>
          <div>
            <label className=" font-semibold text-[18px]    text-[#8C9096]   mb-10">
            {t('TRANSFER.USD')}:
            </label>
            <div className=" text-black font-semibold mt-2">
              {t('TRANSFER.USD_Account')}
            </div>
          </div>

          {/* Show error message to your customers */}
          {/* {errorMessage && <div>{errorMessage}</div>} */}
        </div>
      </div>
    </div>
  );
};

export default Bank;
