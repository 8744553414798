import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaXmark } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import getCurrencySymbol from "../helpers/getCurrencySymble";
import usePaymentRequest from "./PRHooks";
// import { getInputByName } from "../helpers/getInputElemByName";
import {
  emailRegex,
  handleValidation,
} from "../helpers/handlePaymentValidation";

const BASE_URL = process.env.REACT_APP_API_URL;
const CheckoutForm = ({
  amount,
  cur,
  setCard,
  clientSecret,
  tab,
  formReset,
  lang,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  let [loading, setLoading] = useState(false);
  const [doner, setDoner] = useState(null);
  const [isPaymentButtonDisabled, setPaymentButtonDisabled] = useState(true);
  const handleMonthlyPayment = useCallback(
    async (
      e = null,
      paymentMethodId,
      { name, email, cur, amount, lang, formReset }
    ) => {
      try {
        const successAction = () => {
          setLoading(false);
          formReset();
          e && e.complete("success");
          navigate("/result?redirect_status=succeeded");
        };

        // Create a subscription
        const response = await fetch(`${BASE_URL}/create-subscription`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            payment_method: paymentMethodId,
            name,
            cur,
            email,
            amount,
            locale: lang,
          }),
        });

        const { clientSecret, status, error } = await response.json();

        if (
          status === "requires_action" ||
          status === "requires_source_action"
        ) {
          // Handle additional actions
          const result = await stripe.confirmCardPayment(clientSecret);
          if (result.error) {
            toast.error(result.error.message);
            setLoading(false);
          } else {
            // Subscription successful
            successAction();
          }
        } else if (status === "succeeded") {
          // Subscription successful

          successAction();
        } else {
          setLoading(false);
          e.complete("success");
          e && navigate("/result?redirect_status=failed");
          toast.error(t(error.message));
        }
      } catch (error) {
        console.log(error);
        navigate("/result?redirect_status=failed");
        toast.error(error.message);
        setLoading(false);
      }
    },
    [stripe, navigate, setLoading, t] // Include any other dependencies used in the function
  );

  // const handleValidation = (e) => {
  // 	const validateEmtyField = (name) => {
  // 		const elem = getInputByName(e, name);
  // 		elem.focus();
  // 		toast.warn(t("ERRORS.FIELD_REQUIRED"));
  // 	};
  // 	if (name === "") return validateEmtyField("name");
  // 	if (email === "") return validateEmtyField("email");
  // 	const isValidEmail = emailRegex.test(email);
  // 	if (!isValidEmail) {
  // 		const elem = getInputByName(e, "email");
  // 		elem.focus();
  // 		return toast.warn(t("ERRORS.INVALID_EMAIL"));
  // 	}
  // 	return "success";
  // };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (handleValidation({ e: event, t, name, email, toast }) !== "success")
        return;
      if (!stripe || !elements) {
        toast.warning("Stripe hasn't yet loaded");
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      // Set loading state
      setLoading(true);

      // card options
      const options = {
        payment_method: {
          card: elements.getElement("card"),
          billing_details: {
            name,
            email,
          },
        },
      };

      const incompletErrorMsg = (error) => {
        const cardIncomplete =
          error.message === "Your card number is incomplete.";
        toast.error(
          cardIncomplete ? t("ERRORS.INCOMPLETE_CARD_NUMBER") : error.message
        );
      };
      if (tab === "once") {
        const { error } = await stripe.confirmCardPayment(
          clientSecret,
          options
        );
        if (error) {
          // console.log(error);
          incompletErrorMsg(error);
          setLoading(false);
        } else {
          // console.log(paymentIntent);
          formReset();
          navigate("/result?redirect_status=succeeded");
        }
        return;
      }

      // Create a payment method
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("card"),
      });

      if (error) {
        incompletErrorMsg(error);
        setLoading(false);
        return;
      }

      // Handle the payment method success
      await handleMonthlyPayment(null, paymentMethod.id, {
        name,
        email,
        cur,
        amount,
        lang,
        formReset,
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
      toast.error(error.message);
      // Handle or log the error as needed
    }
  };

  const paymentRequest = usePaymentRequest({
    stripe,
    elements,
    cur,
    amount,
    tab,
    formReset,
    handleMonthlyPayment,
    clientSecret,
    doner,
    setDoner,
    lang,
  });

  useEffect(() => {
    const isValid = emailRegex.test(email);
    isValid && setPaymentButtonDisabled(false);
  }, [email]);

  return (
    <div className="flex flex-col sm:flex-row w-full sm:w-[50rem] overflow-hidden rounded-xl">
      <div className="border-2  basis-[40%] bg-slate-40 bg-blue-100 border-r-2 border-blue-100 w-full"></div>
      <div className="px-4 sm:px-8 py-6 basis-[60%]">
        <div className="flex justify-end">
          <FaXmark
            className="text-2xl cursor-pointer text-blue-500"
            onClick={() => {
              formReset();
              setCard(false);
            }}
          />
        </div>
        <h4 className="text-black font-semibold text-lg mb-4">
          {t("MODAL.PAYMENT_DETAILS")}
        </h4>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4 mt-4 sm:mt-8">
            <div>
              <label className="font-semibold text-lg text-[#8C9096] mb-2 sm:mb-4">
                {t("MODAL.FULL_NAME")}
              </label>
              <input
                value={name}
                name="name"
                onChange={(e) => setName(e.target.value)}
                className="w-full text-black focus:outline-blue-600 bg-[#FBFCFD] border-2 border-slate-200 mt-2 sm:mt-3 px-3 py-3 rounded-[10px]"
              />
            </div>
            <div>
              <label className="font-semibold text-lg text-[#8C9096] mb-2 sm:mb-4">
                {t("MODAL.EMAIL")}
              </label>
              <input
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full text-black bg-[#FBFCFD] focus:outline-[#0057B7] border-2 border-slate-200 mt-2 sm:mt-3 px-3 py-3 rounded-[10px]"
              />
            </div>
            <div className="border-2 border-slate-200 focus:outline-blue-600 rounded-[10px] px-3 py-3 sm:py-4">
              <CardElement />
            </div>
            <div>
              <button
                className="bg-blue-600 flex items-center justify-center gap-2 w-full py-3 rounded-xl"
                disabled={!stripe || loading}
              >
                <span className="">
                  {t("DONATE")} {amount} {getCurrencySymbol(cur)}
                </span>
                <ClipLoader
                  loading={loading}
                  color={"white"}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </button>
            </div>
            <div style={{ position: "relative" }}>
              {paymentRequest && (
                <PaymentRequestButtonElement
                  onClick={() => setDoner({ name, email })}
                  options={{ paymentRequest }}
                />
              )}

              {tab !== "once" && isPaymentButtonDisabled && (
                <button
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white overlay
                    zIndex: 2,
                  }}
                ></button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CheckoutForm;
