/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useStripe } from "@stripe/react-stripe-js";
import { getCountryFromCurrency } from "../helpers/getCountryByCurrency";


const usePaymentRequest = ({
	stripe,
	elements,
	cur,
	amount,
	lang,
	tab,
	formReset,
	clientSecret,
	doner,
	setDoner,
	handleMonthlyPayment,
}) => {
	const [paymentRequest, setPaymentRequest] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (!stripe || !elements) {
			return;
		}
		const pr = stripe?.paymentRequest({
			country: getCountryFromCurrency(cur),
			currency: cur,
			total: {
				label: "Donation",
				amount: amount * 100,
			},
			requestPayerName: true,
			requestPayerEmail: true,
		});
		pr.canMakePayment().then((result) => {
			if (result) {
				setPaymentRequest(pr);
			}
		});
	}, [stripe, elements]);

	useEffect(() => {
		doner &&
			paymentRequest &&
			paymentRequest.on("paymentmethod", async (e) => {
				let stripeError = null;
				if (tab === "once") {
					const response = await stripe.confirmCardPayment(
						clientSecret,
						{
							payment_method: e.paymentMethod.id,
						}
					);

					stripeError = response?.error;
				} else {
					await handleMonthlyPayment(e, e.paymentMethod.id, {
						...doner,
						cur,
						amount,
						lang,
						formReset,
					});
					return;
				}

				if (stripeError) {
					e.complete("success");
					navigate("/result?redirect_status=failed");
					tab === "monthly" && window.location.reload();
					return;
				}
				e.complete("success");
				navigate("/result?redirect_status=succeeded");
				tab === "monthly" && window.location.reload();
				formReset();
			});
	}, [paymentRequest, doner]);
	return paymentRequest;
};

export default usePaymentRequest;
