import { useRef, useState } from "react";
import Modal from "../popupmodal/Modal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardPayment from "../carrdPayment/CardPayments";
import Bank from "../../components/bank";
import PaypalPayments from "../paypalPayment/paypalPayment";
import { toast } from "react-toastify";
import MainForm from "./MainForm";
import { useTranslation } from "react-i18next";
const BASE_URL = process.env.REACT_APP_API_URL;

function PaymentForm({ lang, setLang }) {
  const stripePromise = loadStripe(process.env.REACT_APP_PUL_KEY);
  const formRef = useRef();
  const [tab, setTab] = useState("monthly");
  const [paypal, setPayPal] = useState(false);
  const [method, setMethod] = useState("card");
  const [loading, setLoading] = useState(false);
  const [userPayment, setUserPayment] = useState({
    amount: "",
    cur: "",
  });
  const [card, setCard] = useState(false);
  const [bank, setBank] = useState(false);
  const [client, setCLient] = useState({ client_secret: "" });

  // Handle changes in input fields
  const handleChange = (e) => {
    setUserPayment({ ...userPayment, [e.target.name]: e.target.value });
  };
  const { t } = useTranslation();
  // Function to create payment intent
  const createPaymentIntent = async (isGoogle) => {
    try {
      setLoading(true);
      const res = await fetch(`${BASE_URL}/create-intent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: userPayment.amount,
          currency: userPayment.cur,
          payment_method_types: [method],
          locale: lang,
        }),
      });
      setLoading(false);
      const data = await res.json();
      if (!data.success) {
        setLoading(false);
        toast.error(data.msg);
        return null;
      }
      return data;
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error?.message);
    }
  };

  // Handle payment submission
  const handlePayment = async () => {
    try {
      if (userPayment.cur === "ron" && method === "paypal") {
        toast.info(t("ERRORS.UNSUPPORTED_CURRENCY"));
        return;
      }
      if (userPayment.amount === "1" && userPayment.cur === "ron") {
        toast.info(t("ERRORS.MIN_AMOUNT_LEI"));
        return;
      }

      if (method === "card") {
        if (tab !== "once") {
          setCLient({ client_secret: "not required" });
          setCard(true);
          return;
        }
        const paymentIntent = await createPaymentIntent();
        if (!paymentIntent) return;
        // if(!paymentIntent?.success)
        setCLient(paymentIntent);
        setCard(true);
        return;
      }

      if (method === "paypal") {
        setLoading(true);
        if (tab === "once") {
          const paymentIntent = await createPaymentIntent();
          if (!paymentIntent) return;
          setCLient(paymentIntent);
          setPayPal(true);
          // formReset()
          setLoading(false);
          return;
        }

        const setupIntent = await fetch(`${BASE_URL}/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            payment_method_data: method,
          }),
        });
        const data = await setupIntent.json();
        setLoading(false);
        if (!data) return;
        setPayPal(true);
        setCLient(data);
      }

      if (method === "transfer") {
        setBank(true);
        // formReset()
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
      console.log(error);
    }
  };

  // Configuration options for Stripe Elements
  const stripeOptions = {
    clientSecret: client?.client_secret && client?.client_secret,
    appearance: {
      theme: "stripe",
    },
  };

  const formReset = () => {
    setUserPayment({ amount: "", cur: "" });
    setMethod("card");
    setTab("once");
    setCLient({ client_secret: "" });
    formRef.current.reset();
  };

  return (
    <div
      style={{
        backgroundImage: 'url("./rr.webp")',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      {/* Bank Modal */}
      <Modal isOpen={bank}>
        <Bank setBank={setBank} />
      </Modal>
      {/* Card Payment Modal */}
      <Modal isOpen={card}>
        {client?.client_secret && (
          <Elements stripe={stripePromise}>
            <CardPayment
              lang={lang}
              clientSecret={client?.client_secret}
              amount={userPayment.amount}
              cur={userPayment.cur}
              setCard={setCard}
              tab={tab}
              formReset={formReset}
            />
          </Elements>
        )}
      </Modal>
      {/* PayPal Payment Modal */}
      <Modal isOpen={paypal}>
        {client?.client_secret && (
          <Elements stripe={stripePromise} options={stripeOptions}>
            <PaypalPayments
              lang={lang}
              amount={userPayment.amount}
              cur={userPayment.cur}
              clientSecret={client?.client_secret}
              intentId={client.id}
              setPayPal={setPayPal}
              formReset={formReset}
              tab={tab}
            />
          </Elements>
        )}
      </Modal>

      <MainForm
        setMethod={setMethod}
        method={method}
        tab={tab}
        setTab={setTab}
        loading={loading}
        handleChange={handleChange}
        handlePayment={handlePayment}
        formRef={formRef}
        userPayment={userPayment}
        lang={lang}
        setLang={setLang}
      />
    </div>
  );
}

export default PaymentForm;
