// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./locales/translation.json";

i18n.use(initReactI18next).init({
	resources: translations,
	lng: "ro", // Default language
	fallbackLng: "en", // Fallback language if translation is missing
	interpolation: {
		escapeValue: false, // React already does escaping
	},
});

export default i18n;
