
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import { FaXmark } from "react-icons/fa6";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_API_URL;
export const Item = ({ item, setDetail, lang: lng }) => {
	const {t} = useTranslation()
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const handleCancel = async (id) => {
		try {
			setLoading(true);
			const res = await fetch(`${BASE_URL}/cancel`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json", // Adjust the content type as needed
				},
				body: JSON.stringify({ id }),
			});
			const data = await res.json();
			if (data.subscription) {
				setDetail([]);
				navigate(`/?lang=${lng}`);
				toast.success(t(data.subscription.message));
			} else {
				setLoading(false);
				toast.error(data?.error?.message);
			}
		} catch (error) {
			setLoading(false);
			toast.error(error.message);
		}
	};
	return (
		<div className="text-black flex gap-10 rounded border-2 border-green-500 bg-white p-2">
			<div className="font-bold ">
				<p>{t('CANCEL_PAGE.CUSTOMER_NAME')}: {item.metadata.customerName}</p>
				<p>
					{t('CANCEL_PAGE.AMOUNT')}: {item.plan.amount / 100}{" "}
					{item.plan.currency.toUpperCase()}
				</p>
			</div>
			<button
				onClick={() => handleCancel(item.id)}
				className=" flex items-center justify-center gap-2 capitalize btn bg-red-500 p-2 h-10 text-white   rounded-md ">
				{t('CANCEL_PAGE.CANCEL')}
				<ClipLoader
					color={"white"}
					loading={loading}
					size={20}
					aria-label="Loading Spinner"
					data-testid="loader"
				/>
			</button>{" "}
		</div>
	);
};


