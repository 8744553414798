import { useLocation, useNavigate } from "react-router-dom";
import SuccessMessage from "./Success";
import FailedMessage from "./Failed";
import { useEffect } from "react";
function Results({ lang }) {
	const navigate = useNavigate();
	const search = useLocation().search;
	const searchParams = new URLSearchParams(search);
	const redirect_status = searchParams.get("redirect_status");
	const isLangExist = searchParams.get("lang");
	const handleBack = () => {
		navigate(`/?lang=${lang}`);
	};

	useEffect(() => {
		!isLangExist && navigate(`/result/${search}&lang=${lang}`);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			{redirect_status === "succeeded" ? (
				<SuccessMessage handleBack={handleBack} />
			) : (
				<FailedMessage handleBack={handleBack} />
			)}
		</>
	);
}

export default Results;
