import React, { useState } from "react";
// import { FaXmark } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import Modal from "../popupmodal/Modal";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import { Item } from "./subscriptionLIstModal";
import { useTranslation } from "react-i18next";

const baseUrl = process.env.REACT_APP_API_URL;

const CancelSubscription = ({ lang: lng }) => {
  const [email, setEmail] = useState("");
  const [details, setDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const handleSearch = async () => {
    try {
      setLoading(true);
      const res = await fetch(`${baseUrl}/susbriction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });
      const data = await res.json();
      // console.log({searchData:data})
      setLoading(false);
      if (!data) return;
      if (data.error) return toast.error(t(data.error));
      setDetail(data);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error.message);
    }
  };
  // const lng = new URLSearchParams(useLocation().search).get("lang");
  return (
    <div
      className="min-h-screen flex justify-center flex-col items-center"
      style={{
        backgroundImage: 'url("./rr.webp")',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="bg-white border-r-2 border-blue-300 flex flex-col gap-6 p-6 rounded-md shadow-md mx-2 sm:mx-0">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-bold">{t("CANCEL_SUBSCRIPTION")}</h1>
          <Link
            className=" bg-gray-400 text-white px-3 py-1 rounded hover:bg-gray-500 transition duration-300"
            to={`/?lang=${lng}`}
          >
            {t("CANCEL_PAGE.BACK")}
          </Link>
        </div>
        <div className="relative">
          <label className="font-semibold text-lg text-gray-800 mb-4">
            {t("CANCEL_PAGE.ENTER_EMAIL")}
          </label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full text-gray-800 focus:outline-none bg-white border-2 border-gray-300 mt-2 px-3 py-3 rounded-md shadow-sm"
          />
        </div>
        <div className="mx-5">
          {details?.map((item, ind) => (
            <Item key={ind} lang={lng} item={item} setDetail={setDetail} />
          ))}
        </div>
        <div>
          <button
            onClick={handleSearch}
            disabled={!email}
            className="flex items-center justify-center gap-2 w-full bg-blue-600 hover:bg-blue-700 py-3 rounded-md px-3 text-white focus:outline-none"
          >
            {t("CANCEL_PAGE.SEARCH")}
            <ClipLoader
              color={"white"}
              loading={loading}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </button>
        </div>

        {/* <Modal isOpen={isOpen}>
					<SubscriptionListModal
						setIsOpen={setIsOpen}
						setDetail={setDetail}
						items={details}
					/>
				</Modal> */}
      </div>
    </div>
  );
};

export default CancelSubscription;
